<template>
  <v-autocomplete
    v-model="projectSelected"
    :items="projectOptions"
    :loading="loadingProjects"
    :search-input.sync="search"
    placeholder="Start typing to search for a project..."
    clearable
    filled
    class="multi"
    return-object
    no-filter
    @change="emitValues"
  ></v-autocomplete>
</template>

<script>
export default {
  data: () => ({
    projectSelected: null,
    projectOptions: [],
    search: "",
    loadingProjects: false,
  }),
  computed: {
    projectInfo() {
      return this.$store.state.projectInfo;
    },
  },
  watch: {
    search(value) {
      if (!value || value.length < 3) {
        return;
      }
      this.searchDebounce(value);
    },
    projectSelected(value) {
      if (!value.value) {
        return;
      }
      this.$store.state.projectInfo = value; // projectInfo is saved in store
    },
  },
  methods: {
    emitValues() {
      this.$emit("update:values");
    },

    doSearch: async (value, self) => {
      if (!value) {
        self.projectOptions = [];
        self.setSelected();
      }
      if (self.loadingProjects) {
        return;
      }
      self.loadingProjects = true;

      let filter = "";
      if (value.match("^[0-9]{8}$") || value.match("^[0-9]{6}-[0-9]{2}$")) {
        value = value.replace(/-/g, "");
        filter = `JobCode eq '${value}'`;
      } else {
        filter =
          "(contains(JobNumber, '" +
          value.replace("-", "") +
          "') or startswith(ProjectCode, '" +
          value.replace("-", "") +
          "') or contains(JobNameLong, '" +
          value +
          "') or contains(JobNameShort, '" +
          value +
          "')) and StartDate gt 2015-01-01T00:00:00.000Z";
      }

      try {
        const response = await self.$cds.get("cds/odata/Jobs", {
          // THIS IS PROJECT INFO
          params: {
            $filter: filter,
            $select:
              "ProjectCode,JobCode,JobNameLong,JobNameShort,JobNumber,JobSuffix",
            $orderby: "ProjectCode desc",
            $top: 50,
          },
        });

        if (response.data.value.length > 0) {
          // Custom formatting for display in search bar
          console.log(response);
          self.projectOptions = response.data.value.map((x) => {
            return {
              text: x.JobNumber + "-" + x.JobSuffix + " " + x.JobNameShort,
              value: x.JobCode,
              projectCode: x.ProjectCode,
              jobCode: x.JobCode,
              jobNameShort: x.JobNameShort,
            };
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        self.loadingProjects = false;
      }
    },

    searchDebounce(value) {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.doSearch(value, this);
      }, 500);
    },
  },
};
</script>
