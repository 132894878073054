<template>
  <div>
    <v-progress-linear
      v-if="CompletedScoreComputed == 0"
      color="#FFFFFF"
      height="10"
      :value="CompletedScoreComputed"
      rounded
      background-color="#EAEAEA"
    ></v-progress-linear>
    <v-progress-linear
      v-if="CompletedScoreComputed == 25"
      color="#AB604F"
      height="10"
      rounded
      :value="CompletedScoreComputed"
      background-color="#EAEAEA"
    ></v-progress-linear>
    <v-progress-linear
      v-if="CompletedScoreComputed == 50"
      color="#D68F74"
      height="10"
      :value="CompletedScoreComputed"
      rounded
      background-color="#EAEAEA"
    ></v-progress-linear>
    <v-progress-linear
      v-if="CompletedScoreComputed == 75"
      color="#D8BC64"
      height="10"
      :value="CompletedScoreComputed"
      rounded
      background-color="#EAEAEA"
    ></v-progress-linear>
    <v-progress-linear
      v-if="CompletedScoreComputed == 100"
      color="#5BA300"
      height="10"
      :value="CompletedScoreComputed"
      rounded
      background-color="#EAEAEA"
    ></v-progress-linear>
  </div>
</template>

<script>
import mixin from "../mixins/mixin.js";

export default {
  mixins: [mixin],

  props: ["JobCode"],
  data() {
    return {
      CompleteScore: 0,
    };
  },
  async mounted() {
    try {
      await this.$dip
        .get(
          `${this.$store.state.ApiUri}/GetPlanScoreCalculationFunction?JobCode=${this.JobCode}`
        )
        .then((response) => {
          this.CompleteScore = response.data.ProgressScore;
        });
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    CompletedScoreComputed() {
      return this.CompleteScore;
    },
  },
};
</script>
