<template>
  <div class="v-app-bar">
    <v-row>
      <v-col>
        <h2>Digital Inception Plan Dashboard</h2>
        <div class="search">
          <DipSearch />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="slider-wrapper">
          <div class="slider" ref="slider">
            <div class="slide-track" ref="slideTrack">
              <div v-for="i in 10" class="slide" :style="getStyleForCard()">
                <v-card
                  @mousedown="startDrag"
                  @mousemove="drag"
                  @mouseup="endDrag"
                  @mouseleave="endDrag"
                  :color="getRandomColor(i)"
                  width="400"
                  height="220"
                  rounded="xl"
                >
                  <v-card-text>
                    <v-icon x-large> mdi-format-quote-open </v-icon>
                    <p class="font-italic">
                      {{ getTestimonialContent(i) }}
                    </p>
                    <v-flex offset-xs4>
                      <p class="text-right">
                        <v-icon x-large> mdi-format-quote-close </v-icon>
                        {{ getTestimonialAuthor(i) }} -
                        {{ getTestimonialAuthorLocation(i) }}
                      </p>
                    </v-flex>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="v-app-bar right">
          <NewDipDialog />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NewDipDialog from "@/components/NewDipDialog.vue";
import DipSearch from "@/components/DipSearch.vue";
export default {
  name: "App",
  components: {
    NewDipDialog,
    DipSearch,
  },

  data: () => ({
    completeCount: null,
    progressCount: null,
    RecentDipProgress: null,
    theme: "default",
    lastNumber: 0,
    testimonialData: [
      {
        content:
          "The Digital Inception Reviews are becoming a standard for our projects now – so far all of them brought to us tangible benefits; I wouldn’t want to miss this opportunity on any of my projects.",
        author: {
          name: "Luca Stabile",
          group: "Italy",
          avatar: "Luca-Stabile.jpg",
        },
      },
      {
        content:
          "The DIP provided focussed time with our team of digital gurus to test … efficiently utilising digital tools and workflows, reshaping approaches and … encouraged us to pause before we launched into the project and prompted us to challenge the norm.",
        author: {
          name: "Pete Thompson",
          group: "NWY PSITSI",
          avatar: "Pete-Thompson.jpg",
        },
      },
      {
        content:
          "The DIP we did for Euston Tower did include some quite experienced facilitators...who had experience of whats going on in Arup, which was helpful as they led us through the process in a fun way and helped us to come to conclusions with inputs from a variety of tools and influences.",
        author: {
          name: "Kate Fletcher",
          group: "LON PSITSI",
          avatar: "Kate-Fletcher.jpg",
        },
      },
      {
        content:
          "For about 5 hours of my time filling out the DIP ahead of time and attending the DIP workshop...We have three fee estimates approximately 35K under review from Irish Water for our initial idea",
        author: {
          name: "Chelsea Merrick",
          group: "Water",
          avatar: "Chelsea-Merrick.jpg",
        },
      },
      {
        content:
          "DIP enabled us to uncover and promote digital initiatives in the built environment that had previously been overlooked, resulting in significant progress for our project.",
        author: {
          name: "Wei Quan Yeow",
          group: "Regional Commercial SIN",
          avatar: "Wei-Quan-Yeow.jpg",
          Region: "Australasia",
        },
      },
      {
        content:
          "As the Digital Transformation Leader in Singapore office, I find DIP a great platform to introduce good digital ideas across the office. The more we use these workflows, the more feedback and the better they will become.",
        author: {
          name: "Mak Swee Chiang",
          group: "SIN",
          avatar: "Swee-Chiang-Mak.jpg",
          Region: "Australasia",
        },
      },
    ],
  }),

  computed: {},

  methods: {
    getTestimonialContent(index) {
      return this.testimonialData[index % this.testimonialData.length].content;
    },
    getTestimonialAuthor(index) {
      return this.testimonialData[index % this.testimonialData.length].author
        .name;
    },
    getTestimonialAuthorLocation(index) {
      return this.testimonialData[index % this.testimonialData.length].author
        .group;
    },
    startDrag(e) {
      this.dragging = true;
      this.startX = e.pageX - this.$refs.slider.offsetLeft;
      this.scrollLeft = this.$refs.slider.scrollLeft;
    },
    drag(e) {
      if (!this.dragging) return;
      e.preventDefault();
      const x = e.pageX - this.$refs.slider.offsetLeft;
      const walk = x - this.startX;
      this.$refs.slider.scrollLeft = this.scrollLeft - walk;
    },
    endDrag() {
      this.dragging = false;
    },
    getRandomColor(i) {
      switch (i % 7) {
        case 1:
          return "#FECACA";
          break;
        case 2:
          return "#FED7AA";
          break;
        case 3:
          return "#FDE68A";
          break;
        case 4:
          return "#D9F99D";
          break;
        case 5:
          return "#A7F3D0";
          break;
        case 6:
          return "#A5F3FC";
          break;
        case 0:
          return "#C7D2FE";
          break;
      }
    },
    getStyleForCard() {
      let max = 3;
      let min = -3;
      let randomRotation = Math.floor(Math.random() * (max - min + 1) + min);
      if (randomRotation < 0 && this.lastRotation < 0) {
        randomRotation = randomRotation * -1;
      }
      this.lastRotation = randomRotation;
      return `margin-right: 20px; margin-left: 20px; margin-top: 10px; margin-bottom: 10px; rotate: ${randomRotation}deg`;
    },
  },
};
</script>

<style scoped src="../styles/testimonials.scss" lang="scss">
.flexcard {
  display: flex;
  flex-direction: column;
}
</style>
