var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',{attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"no-filter":"","hide-selected":"","label":"Load an existing DIP...","placeholder":"Start typing a Project name or Project number to Search","return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":function($event){return _vm.loadDIP(_vm.model)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('span',{domProps:{"textContent":_vm._s(
          (data.item.JobCode ? data.item.JobCode : data.item.ProjectCode) +
          '-' +
          data.item.ProjectName
        )}})]}},{key:"item",fn:function(data){return [_c('span',{domProps:{"textContent":_vm._s(
          data.item.Type +
          ' - ' +
          (data.item.JobCode ? data.item.JobCode : data.item.ProjectCode) +
          ' - ' +
          data.item.ProjectName
        )}}),_c('v-spacer'),(_vm.getLockStatus(data.item) == true)?_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-lock ")]):_vm._e()]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.warningDialog),callback:function ($$v) {_vm.warningDialog=$$v},expression:"warningDialog"}},[_c('div',{attrs:{"align":"center"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("This DIP is locked!")])])],1),_c('v-row',[_c('v-col',[_vm._v(" Please request to be added to the DIP from the following list of Arupians: ")])],1),_vm._l((_vm.attendanceList),function(person){return _c('div',{key:person},[_c('v-row',[_c('v-col',[_c('a',{attrs:{"href":'mailto:' + person}},[_vm._v(_vm._s(person))])])],1)],1)})],2)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }