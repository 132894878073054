var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.projects,"hide-default-footer":"","disable-pagination":"","loading":_vm.loadingLocalData,"loader-height":"0"},scopedSlots:_vm._u([{key:"loading",fn:function(){return [(_vm.loadingLocalData)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey"}}):_vm._e()]},proxy:true},{key:"item",fn:function(row){return [_c('tr',[_c('td',[(row.item.Type == 'Pre Bid')?_c('div',[_vm._v(" "+_vm._s(_vm.truncateJN(row.item.ProjectCode))+" ")]):_c('div',[_vm._v(_vm._s(row.item.JobCode))])]),_c('td',[_c('div',{staticClass:"text-xs-right"},[(row.item.Type == 'Pre Bid')?_c('v-icon',{attrs:{"color":"rgba(230, 30, 40)"}},[_vm._v("mdi-clipboard-text")]):_c('v-icon',{attrs:{"color":"rgba(32, 146, 211)"}},[_vm._v("mdi-briefcase")]),_vm._v(" "+_vm._s(row.item.Type)+" ")],1)]),_c('td',[(_vm.getLockStatus(row.item) == true)?_c('v-icon',[_vm._v("mdi-lock")]):_vm._e()],1),_c('td',[_vm._v(_vm._s(row.item.ProjectName))]),_c('td',[_vm._v(_vm._s(row.item.ProjectDirector.Name))]),_c('td',[(_vm.formatDate(row.item.Modified) !== 'Invalid Date')?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(row.item.Modified))+" ")]):_vm._e(),(_vm.formatDate(row.item.Modified) === 'Invalid Date')?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(row.item.Created))+" ")]):_vm._e()]),_c('td',[(row.item.Type == 'Pre Bid')?_c('div',[_vm._v("N/A")]):_c('div',[_c('StatusScoreTable',{attrs:{"JobCode":row.item.JobCode}})],1)]),_c('td',[(row.item.Type != 'Pre Bid')?_c('div',{staticClass:"mx-n6"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"viewDipIcon item",on:{"click":function($event){return _vm.loadPlan(row.item.JobCode, 'Preparation', 'DIP')}}},'i',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Go to DIP")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.copyLink(
                      row.item.ProjectCode,
                      row.item.ProjectName,
                      'Dip'
                    );
                    _vm.openDialog();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#2092D3"}},[_vm._v(" mdi-link-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy link to clipboard")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.loadPlan(row.item.JobCode, 'Actions', 'DIP')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-clipboard-text-search-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Go to Action Plan")])])],1):_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"viewDipIcon item",on:{"click":function($event){return _vm.loadPlan(row.item.ProjectCode, 'PrebidForm', 'PREBID')}}},'i',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Go to DIP")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.copyLink(
                      row.item.ProjectCode,
                      row.item.ProjectName,
                      'Pre bid'
                    );
                    _vm.openDialog();}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#2092D3"}},[_vm._v(" mdi-link-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy link to clipboard")])])],1)])])]}}])}),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.copyLinkDialog),callback:function ($$v) {_vm.copyLinkDialog=$$v},expression:"copyLinkDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" Share your DIP ")]),_c('v-card-actions',[_c('br'),_vm._v(" The shareable url for "+_vm._s(_vm.tempLink)+" has been copied to your clipboard. "),_c('br'),_c('br')])],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }