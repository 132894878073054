<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="projects"
      hide-default-footer
      class="elevation-1"
      disable-pagination
      :loading="loadingLocalData"
      loader-height="0"
    >
      <template v-slot:loading>
        <v-progress-circular
          v-if="loadingLocalData"
          indeterminate
          color="grey"
        ></v-progress-circular>
      </template>
      <template v-slot:item="row">
        <tr>
          <td>
            <div v-if="row.item.Type == 'Pre Bid'">
              {{ truncateJN(row.item.ProjectCode) }}
            </div>
            <div v-else>{{ row.item.JobCode }}</div>
          </td>

          <td>
            <div class="text-xs-right">
              <v-icon
                v-if="row.item.Type == 'Pre Bid'"
                color="rgba(230, 30, 40)"
                >mdi-clipboard-text</v-icon
              >
              <v-icon v-else color="rgba(32, 146, 211)">mdi-briefcase</v-icon>

              {{ row.item.Type }}
            </div>
          </td>
          <td>
            <v-icon v-if="getLockStatus(row.item) == true">mdi-lock</v-icon>
          </td>
          <td>{{ row.item.ProjectName }}</td>

          <td>{{ row.item.ProjectDirector.Name }}</td>
          <td>
            <div v-if="formatDate(row.item.Modified) !== 'Invalid Date'">
              {{ formatDate(row.item.Modified) }}
            </div>
            <div v-if="formatDate(row.item.Modified) === 'Invalid Date'">
              {{ formatDate(row.item.Created) }}
            </div>
          </td>

          <td>
            <div v-if="row.item.Type == 'Pre Bid'">N/A</div>
            <div v-else>
              <StatusScoreTable :JobCode="row.item.JobCode"> </StatusScoreTable>
            </div>
          </td>
          <td>
            <div v-if="row.item.Type != 'Pre Bid'" class="mx-n6">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <i
                    v-on="on"
                    v-bind="attrs"
                    class="viewDipIcon item"
                    @click="loadPlan(row.item.JobCode, 'Preparation', 'DIP')"
                  >
                  </i>
                </template>
                <span>Go to DIP</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    @click="
                      copyLink(
                        row.item.ProjectCode,
                        row.item.ProjectName,
                        'Dip'
                      );
                      openDialog();
                    "
                    ><v-icon color="#2092D3"> mdi-link-variant</v-icon>
                  </v-btn>
                </template>
                <span>Copy link to clipboard</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    @click="loadPlan(row.item.JobCode, 'Actions', 'DIP')"
                    ><v-icon color="blue"
                      >mdi-clipboard-text-search-outline</v-icon
                    ></v-btn
                  >
                </template>
                <span>Go to Action Plan</span>
              </v-tooltip>
            </div>
            <div v-else>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <i
                    v-on="on"
                    v-bind="attrs"
                    class="viewDipIcon item"
                    @click="
                      loadPlan(row.item.ProjectCode, 'PrebidForm', 'PREBID')
                    "
                  >
                  </i>
                </template>
                <span>Go to DIP</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    @click="
                      copyLink(
                        row.item.ProjectCode,
                        row.item.ProjectName,
                        'Pre bid'
                      );
                      openDialog();
                    "
                    ><v-icon color="#2092D3"> mdi-link-variant</v-icon></v-btn
                  ></template
                >
                <span>Copy link to clipboard</span>
              </v-tooltip>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="copyLinkDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Share your DIP
        </v-card-title>
        <v-card-actions>
          <br />
          The shareable url for {{ tempLink }} has been copied to your
          clipboard. <br /><br />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import dayjs from "dayjs";
import mixin from "../mixins/mixin.js";
import StatusScoreTable from "./StatusScoreTable.vue";

export default {
  mixins: [mixin],
  name: "App",
  components: {
    StatusScoreTable,
  },

  props: ["projectsList", "loadingData"],
  data() {
    return {
      copyLinkDialog: false,
      tempLink: null,
      headers: [
        { text: "Job Number", value: "JobNumber" },
        { text: "Type", value: "Type" },
        { text: "", value: "Lock" },
        {
          text: "Project Name",
          align: "start",
          value: "ProjectName",
        },
        { text: "Project Director", value: "ProjectDirector" },
        { text: "Last Modified", value: "Modified" },
        { text: "Progress", value: "CompleteScore" },
        { text: "", value: "Load", sortable: false },
      ],
      projects: this.projectsList,
      loading: false,
      loadingLocalData: false,
    };
  },
  watch: {
    projectsList: function (newVal) {
      this.projects = newVal;
    },
    loadingData: function (newVal) {
      this.loadingLocalData = newVal;
    },
  },
  methods: {
    truncateJN(JN) {
      if (JN.length > 8) {
        return JN.slice(0, 8) + "...";
      } else {
        return JN;
      }
    },
    debug() {
      console.log(this.projects);
    },
    getLockStatus(item) {
      if (item.hasOwnProperty("DigitalPlan")) {
        if (item.DigitalPlan.hasOwnProperty("Lock")) {
          if (item.DigitalPlan.Lock) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        if (item.PreBidPlan.hasOwnProperty("Lock")) {
          if (item.PreBidPlan.Lock == true) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    openDialog() {
      this.copyLinkDialog = true;
    },
    copyLink(ProjectCode, ProjectName, type) {
      // add conditional dependent on environment here
      if (type === "DIP") {
        // its a DIP plan
        let output = "https://dip.arup.com?project=" + ProjectCode;
        this.tempLink = ProjectName;
        this.$clipboard(output);
      } else {
        // its a prebid plan
        let output =
          "https://dip.arup.com?project=" + ProjectCode + "&type=PreBid";
        this.tempLink = ProjectName;
        this.$clipboard(output);
      }
    },
    formatDate(date) {
      let dateFormatted = dayjs(date).format("D MMM YYYY");
      return dateFormatted;
    },
    async loadPlan(code, route, type) {
      try {
        this.loading = true;
        const result = await this.fullLoadGenericPlanWithCode(
          // load into DIP
          code,
          this.$store.state.ApiUri,
          this.$store.state,
          type
        );

        if (type === "DIP") {
          this.$store.state.plan = result.loadedStore;
        } else {
          this.$store.state.prebidPlan = result.loadedStore;
        }

        this.$store.state.lastModified = result.lastModified;
        this.$session.set(
          "planData",
          type === "DIP" ? this.$store.state.plan : this.$store.state.prebidPlan
        );
        this.loading = false;

        if (route === "Workshop") {
          this.$router.push({ path: route.toLowerCase() + "/1" });
        } else {
          this.$router.push({ name: route });
        }

        console.log(`${type} loaded.`);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style src="../styles/forms.scss" lang="scss">
.text-xs-right {
  white-space: nowrap;

  /* To help visualize the fact that the container is too small */
  width: 10px;
  border: 1px solid #ddd;
}
</style>
