var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-container',[_c('HomeHeader',{attrs:{"type":"Pre-Bid"}}),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.overlay),callback:function ($$v) {_vm.overlay=$$v},expression:"overlay"}},[_c('div',{attrs:{"align":"center"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('br'),_c('h2',[_vm._v("Hi "+_vm._s(_vm.person)+", Welcome to the new DIP!")]),_c('br'),_c('br')])],1),_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Get started here to learn more about the DIP")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue","href":"https://moodle.arup.com/course/view.php?id=5841%C2%A7ion%3D7","target":"_blank"},on:{"click":function($event){_vm.overlay = false}}},[_vm._v(" Go to Moodle Page ")])],1)],1),_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',[_c('br'),_c('h3',[_vm._v("Get help on DIP specific features")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue"},on:{"click":function($event){_vm.overlay = false;
                    _vm.navigate('Help');}}},[_vm._v(" Go to DIP Help Page ")])],1)],1),_c('v-row',[_c('v-col',[_c('br'),_c('h3',[_vm._v("I'm ready to start")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue"},on:{"click":function($event){_vm.overlay = false}}},[_vm._v(" Take me to the DIP ")])],1)],1)],1)],1)],1)]),(_vm.showWarning)?_c('div',[_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"dense":"","border":"left","type":"warning"}},[_vm._v(" You are on the test server! Feel free to create DIPs for testing purposes. "),_c('strong',[_vm._v("Data could be removed at anytime on this site. "),_c('br'),_vm._v(" For the production website please visit: "),_c('a',{attrs:{"target":"_blank","href":"https://dip.arup.com"}},[_vm._v("https://dip.arup.com")])])])],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('h3',[_vm._v(" DIPs I own "),_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("These are plans you have started and are a contributor for")])])],1)])],1),_c('v-row',[_c('v-col',[_c('ProjectDisplayTable',{attrs:{"projectsList":_vm.dipsOwned,"loadingData":_vm.loadingOwner}})],1)],1),_c('v-row',[_c('v-col',[_c('h3',[_vm._v(" DIPs I am involved in "),_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("These are plans that you have been assigned an action to or are registered as a workshop attendee")])])],1)])],1),_c('v-row',[_c('v-col',[_c('ProjectDisplayTable',{attrs:{"projectsList":_vm.dipsInvolved,"loadingData":_vm.loadingAttendee}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }